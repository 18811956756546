<template>
    <div class="list">
        <RequestCard
            v-for="(invitation, index) in dataList"
            :key="index"
            :item="invitation"
            @handleShowDetail="handleShowDetail"
        />
        <div class="list__pagination">
          <b-pagination
            v-if="hasItems"
            v-model="pagination.currentPage"
            :per-page="pagination.itemsPerPage"
            :total-rows="pagination.totalItems"
            @change="handleSearch"
            pills
            align="fill"
          />
        </div>
        <Detail
          v-if="showDetails"
          :request="currentRequest"
          :invitation="invitation"
          @handleClose="handleClose"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getOrdenation } from '@/helpers/ApiHelper';
import { getFormattedDate } from '@/helpers/ComponentHelper';

export default {
  mounted() {
    this.handleSearch();
  },
  props: {},
  data() {
    return {
      // showDetails: false,
      currentRequest: {},
      invitation: {
        id: null,
        status: null,
        type: null,
      },
      dataList: [],
      sort: {
        name: 'status',
        type: 'desc',
      },
    };
  },
  watch: {
    invitationsList(newValue) {
      this.mapToCard(newValue);
    },
  },
  methods: {
    handleSearch(page = 1) {
      this.isTableLoading = true;
      this.$store.dispatch('invitations/getInvitations', this.getSearchInvitationData(page))
        .then(() => {
          this.isTableLoading = false;
        })
        .catch(() => { this.isTableLoading = false; });
    },
    getSearchInvitationData(page = 1) {
      return {
        page,
        perPage: 10,
        filters: this.getInvitationSearchFilters(),
        ordenation: getOrdenation(this.sort),
      };
    },
    getInvitationSearchFilters() {
      const filtersGroup = [];
      filtersGroup.push('type:clinic');
      // filtersGroup.push('type:collaborator');
      return filtersGroup;
    },
    paginate(currentPage) {
      this.handleSearch(currentPage);
    },
    mapToCard(newList) {
      const list = [];
      newList.forEach((currentValue) => {
        list.push({
          patientName: currentValue.patientName,
          specialty: currentValue.specialty.name,
          date: getFormattedDate(currentValue.date),
          status: currentValue.status.toLowerCase(),
          to: currentValue.invited.name,
          reqId: currentValue.request.id,
          invId: currentValue.id,
        });
      });
      this.dataList = list;
    },
    handleShowDetail(reqId, invId) {
      const currentInvitation = this.invitationsList.find((item) => item.id === invId);
      this.currentRequest = currentInvitation.request;
      const documents = [];
      this.currentRequest.documents.forEach((doc) => {
        documents.push(doc.document);
      });
      this.currentRequest.documents = documents;
      this.invitation = {
        id: currentInvitation.id,
        status: currentInvitation.status.toLowerCase(),
        type: 'sent',
      };
      this.showDetails = true;
    },
    handleClose() {
      this.showDetails = false;
    },
  },
  computed: {
    ...mapGetters({
      invitationsList: 'invitations/getInvitationListState',
      pagination: 'invitations/getInvitationPaginationState',
    }),
    showDetails: {
      get() { return this.$store.state.ui.showMobileDetails; },
      set(value) { this.$store.commit('ui/setShowMobileDetails', value); },
    },
    hasItems() {
      return this.invitationsList && this.invitationsList.length > 0;
    },
  },
  components: {
    RequestCard: () => import('@/components/atoms/Cards/RequestCard/RequestCard.vue'),
    Detail: () => import('@/components/organisms/Request/mobileTabs/detail/Detail.vue'),
  },
};
</script>
<style lang="sass" scoped>
  @import './mobileTabs.sass'
</style>
